import deepmerge from 'deepmerge';
import _get from 'lodash/get';

import { getKeys } from '@atc/bonnet-parameters';
import { getReference } from '@atc/bonnet-reference';

import { formatCurrency } from 'atc-js';

import { fbaCustomMetadata } from '@/config/customMetadata';
import metaDescriptions from '@/config/page-data/metaDescriptions';
import pageTitles from '@/config/page-data/pageTitles';
import windowTitles from '@/config/page-data/windowTitles';
import pricesWithCanonicalUrl from '@/config/pricesWithCanonicalUrl.json';

const featureCodeDesc = (code) => {
    const featureCodes = { 1297: 'DVD Player', 1066: 'Navigation', 1136: 'Premium Audio', 1230: 'Portable Audio Connection', 1211: 'Bluetooth, Hands-Free', 1013: 'Security System', 1033: 'Cruise Control', 1306: '3rd Row Seats', 1062: 'Keyless Entry', 1161: 'Steering Wheel Controls', 1224: 'Backup Camera', 1319: 'Heated Seats', 1078: 'Leather Seats', 1132: 'Sunroof', 1317: 'Premium Wheels', 1318: 'Disability Equipped', 1199: 'Trailer Hitch', 1326: 'Apple CarPlay', 1327: 'Android Auto', 1149: 'Satellite Radio', 1172: 'Roof Rack', AT0001: 'Dual Rear Wheels' };
    return _get(featureCodes, code, '');
};

const seoPathOrders = ['vehicleStyleCode', 'driveGroup', 'engineCode', 'fuelTypeGroup'];

const getExtraSelectionNames = (query) => {
    let featureCodes = _get(query, 'featureCodes', []);
    if (typeof featureCodes === 'string') {
        featureCodes = [featureCodes];
    }

    let extraSelectionNames = '';
    let extraSelectionCount = 0;
    for (let i = 0; i < featureCodes.length && extraSelectionCount < 3; i++) {
        if (extraSelectionNames === '') {
            extraSelectionCount += 1;
            extraSelectionNames = extraSelectionNames.concat(featureCodeDesc(featureCodes[i]));
        } else {
            extraSelectionCount += 1;
            extraSelectionNames = extraSelectionNames.concat(', ', featureCodeDesc(featureCodes[i]));
        }
    }
    return extraSelectionNames;
};

const getElectricMileRange = (code = '') => {
    let electricMileRange = '';
    const nomaxind = code.split('-') > 1 ? '' : 'over ';
    if (code) {
        electricMileRange = 'with @@nomaxind@@@@code@@ Mile Range';
        electricMileRange = electricMileRange.replace('@@nomaxind@@', nomaxind);
        electricMileRange = electricMileRange.replace('@@code@@', code);
    }
    return electricMileRange;
};

const stringElement = (query, code) => {
    const v = _get(query, code, '');
    if (typeof v === 'string') {
        return v;
    }
    if (v.length && v.length > 1) {
        return '';
    }
    return v[0];
};

const getOption = (code = '', options = []) => {
    for (let i = 0; i < options.length; i++) {
        if (code === options[i].code) {
            return options[i];
        }
    }
};

const getCustomPageName = (brand, query, originalPageName) => {
    if (brand === 'ford') {
        if (query.vehicleExchange) {
            return 'moneyBackGuarantee';
        }
        if (query.vehicleUseType?.includes('COMMERCIAL') && !query.vehicleUseType?.includes('PERSONAL')) {
            return 'commercialVehicles';
        }
    }
    return originalPageName;
};

const templateData = (config = {}, type = '', brand = 'atc', pageName = '', query = {}) => {
    const brandConfig = _get(config, brand, {});
    const pageConfig = _get(brandConfig, getCustomPageName(brand, query, pageName), {});
    const template = _get(pageConfig, type, '');
    return template;
};

const numberWithCommas = (x) => {
    if (x && !x.includes(',')) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return x;
};

const assignPriceData = ({ minPrice = false, maxPrice = false }) => {
    if (!minPrice && maxPrice && pricesWithCanonicalUrl.find((el) => el.price === parseInt(maxPrice, 10))) return 'Under $' + numberWithCommas(maxPrice) + ' ';
    return '';
};

const assignPriceStats = (minPrice = null, maxPrice = null) => {
    if (minPrice && maxPrice && minPrice !== maxPrice) {
        return ' ranging in price from ' + minPrice + ' to ' + maxPrice;
    }
    return '';
};

const assignMpgData = (query, target) => {
    // don't try this unless it's a single string and not an array
    const mpgRanges = query[getKeys('mpgRanges')[target]];
    if (mpgRanges && typeof mpgRanges === 'string') {
        const low = mpgRanges.split('-')[0];
        if (low && low !== '0') {
            return 'with ' + low + ' MPG ';
        }
    }
    return '';
};

const assignEngineData = (query, target, engineCodesRef) => {
    const engineCode = stringElement(query, getKeys('engineCodes')[target]);
    return _get(getOption(engineCode, engineCodesRef), 'name', '');
};

const assignFuelData = (query, fuelTypeRef) => {
    const fuelType = stringElement(query, 'fuelTypeGroup');
    return _get(getOption(fuelType, fuelTypeRef), 'name', '');
};

const assignOwnerData = (query, target) => {
    const sellerType = stringElement(query, getKeys('sellerTypes')[target]);
    if (sellerType.trim() === 'p') {
        return 'by Owner ';
    }
    return '';
};

const processTemplate = (target, key, resource, brand = 'atc', pageName = '', query = {}, primarySelectionName = '', engine = '', fuel = '', address = {}, style = '', listingId = '', title = '') => {
    const extraSelectionNames = getExtraSelectionNames(query);
    let template = templateData(resource, key, brand, pageName, query);

    // I use the canonical path logic, we will remove the price if there is higher priority filters in the list filter order
    if (resource === pageTitles || resource === windowTitles) {
        query = { ...query };
        const hasSeoFilters = seoPathOrders.some((filter) => {
            const keyFilter = (getKeys(filter) || {})[target] || filter;
            return Object.keys(query).includes(keyFilter);
        });
        if (hasSeoFilters) {
            delete query.maxPrice;
            delete query.minPrice;
        }
    }

    template = template.replace('@@name@@', primarySelectionName);
    template = template.replace('@@extra@@', extraSelectionNames);
    template = template.replace('@@price@@', assignPriceData(query));
    template = template.replace('@@mpg@@', assignMpgData(query, target));
    if (address && Object.keys(address).length) {
        const { city = '', state = '', zip = '' } = address;
        template = template.replace('@@city@@', city);
        template = template.replace('@@state@@', state);
        template = template.replace('@@zip@@', zip);
    } else {
        const { city = '', state = '', zip = '' } = query;
        template = template.replace('@@city@@', city);
        template = template.replace('@@state@@', state);
        template = template.replace('@@zip@@', zip);
    }
    template = template.replace('@@engine@@', engine);
    template = template.replace('@@fuel@@', fuel);
    template = template.replace('@@owner@@', assignOwnerData(query, target));
    template = template.replace('@@style@@', style);
    template = template.replace('@@id@@', listingId);
    template = template.replace('@@title@@', title);

    return template.replace(/\s\s+/g, ' ').trimEnd();
};

const getKey = (query, base = '', isNational, addExtra = true) => {
    const extraSelectionNames = getExtraSelectionNames(query);
    const extraKey = extraSelectionNames && addExtra ? 'extra' : '';
    let key = base + 'national';
    if (!isNational && _get(query, 'city', null) && _get(query, 'state', null)) {
        key = base;
    }
    return key + extraKey;
};

const getStyleOnly = (query, target, vehicleStyleCodes) => {
    let styleOnly = {};
    const vehicleStyleCode = stringElement(query, getKeys('vehicleStyleCodes')[target]);
    if (vehicleStyleCodes && vehicleStyleCodes.length > 0) {
        styleOnly = getOption(vehicleStyleCode, vehicleStyleCodes);
    }
    return styleOnly;
};

const getStylePlus = (query, target, driveCodes, fuelCodes, vehicleStyleCodes) => {
    let stylePlus = {};
    const vehicleStyleCode = stringElement(query, getKeys('vehicleStyleCodes')[target]);
    const driveGroup = _get(query, 'driveGroup', []);
    const fuelTypeGroup = _get(query, 'fuelTypeGroup', []);
    if (driveGroup.length > 1 && driveGroup.includes('AWD4WD')) {
        stylePlus = getOption('AWD4WD', driveCodes);
    } else if (fuelTypeGroup.length > 1 && fuelTypeGroup.includes('HYB')) {
        stylePlus = getOption('HYB', fuelCodes);
    } else if (fuelTypeGroup.length > 1 && fuelTypeGroup.includes('ELE')) {
        stylePlus = getOption('ELE', fuelCodes);
    } else if (fuelTypeGroup.length > 1 && fuelTypeGroup.includes('PIH')) {
        stylePlus = getOption('PIH', fuelCodes);
    } else {
        stylePlus = getOption(vehicleStyleCode, vehicleStyleCodes);
    }
    return stylePlus;
};

const getFuelTypeSummaryContent = (query) => {
    const fuelTypeGroup = _get(query, 'fuelTypeGroup', []);
    if (fuelTypeGroup.length > 1 && fuelTypeGroup.includes('ELE')) {
        return 'electric ';
    }
    return '';
};

const separate = (code) => {
    let s = '';
    if (code) {
        s = ''.concat(code, ' ');
    }
    return s;
};

const getYear = (query) => {
    const end = _get(query, 'endYear', null);
    const start = _get(query, 'startYear', null);

    if (end && start && end === start) return end;
    return '';
};

const assignTrim = (template, query, target, trim, options) => {
    if (!template) return template;

    const { brand, pattern = '@@trim@@' } = options ?? {};

    const trimCode = stringElement(query, getKeys('trimCodeList')[target]);

    if (!trim && trimCode) {
        trim = trimCode.split('|')[1];
    }

    return template.replace(pattern, !brand || brand === 'ford' ? separate(trim) : '');
};

const primarySelectionName = (query, target, listingType, make, series, model, trim = '', electricRange, stylePlus, stylePlusCode, style, year, brand = 'atc') => {
    const calledVehicleStyles = ['COMMERCIAL', 'AWD4WD', 'LUXURY', 'HYBEL', 'HYB', 'ELE'];

    let label = '';
    if (model === '' && stylePlus === '' || electricRange) {
        if (calledVehicleStyles.includes(stylePlusCode) && !!electricRange) {
            label = 'Vehicles';
        }
        label = 'Cars';
    }

    if ((model === '' && make !== '') || (model === '' && make === '')) {
        switch (stylePlusCode) {
            case 'ELE':
                label = 'Cars';
                break;
            case 'HYB':
                label = 'Vehicles';
                break;
            default:
        }
    } else if (make !== '' && model !== '') {
        switch (stylePlusCode) {
            case 'ELE':
            case 'HYB':
                label = '';
                break;
            default:
        }
    }

    // Add 'Vehicles' after 'AWD/4WD' drive type in all cases except when a make is selected
    if (make && stylePlusCode === 'AWD4WD') {
        stylePlus = `Vehicles with ${stylePlus}`;
    } else if (stylePlusCode === 'AWD4WD') {
        stylePlus = `${stylePlus} Vehicles`;
    }

    let selectionName = ''.concat(brand === 'ford' ? '' : separate(listingType), separate(getYear(query)), separate(make), separate(series), separate(model), assignTrim('@@trim@@', query, target, trim), separate(stylePlus), separate(label), separate(electricRange));

    if (year) {
        selectionName = ''.concat(brand === 'ford' ? '' : separate(listingType), separate(year), separate(make), separate(series), separate(model), separate(trim), separate(style));
    }

    if (selectionName.trim().match(/[a-zA-Z]$/) && !selectionName.includes('Vehicles') && !selectionName.includes('Cars') && style && selectionName.trim().endsWith(style)) {
        return selectionName.trim() + 's';
    }
    return selectionName.trim();
};

const getListingType = (listing, brand) => {
    let listingType = listing.type || listing.listingType;

    // get actual name from listingTypes
    if (listingType && Array.isArray(listing.listingTypes)) {
        const type = listing.listingTypes.find(({ code }) => listingType === code);
        if (type) {
            listingType = type.name;
        }
    }

    // Used FORD_BLUE_CERT vehicles will be treated as Certified on Ford WhiteLabel
    if (brand === 'ford' && listingType && listingType.toLowerCase() === 'used') {
        listingType = 'Certified';
    }

    return listingType;

};

// TODO: revisit after adding reference data to state.
const buildListingTitle = (listing, brand) => {
    const type = getListingType(listing, brand);

    const make = listing.make?.name;
    const model = listing.model?.name;
    const trim = listing.trim?.name;

    let title = `${type} ${listing.year} ${make} ${model}`;
    if (listing.trim) {
        title = `${title} ${trim}`;
    }
    return title;
};

const buildTitleList = (listings, listingType, brand) => {
    const titles = [];
    if (listings) {
        for (let i = 0; i < listings.length; i++) {
            const title = buildListingTitle(listings[i], brand).replace(listingType, '');
            if (!titles.includes(title)) {
                titles.push(title);
            }
        }
        titles.sort();
    }
    return titles;
};

const sampleListingTitles = (listings, listingType, brand) => {
    const titles = buildTitleList(listings, listingType, brand);
    let sampleListings = '';
    if (titles.length > 1) {
        if (titles.length === 2) {
            sampleListings = ', including a ' + titles[0] + ' and a ' + titles[1];
        } else if (titles.length > 2) {
            sampleListings = ', including a ' + titles[0] + ', a ' + titles[1] + ', and a ' + titles[2];
        }
    }
    return sampleListings;
};

const getCarText = (totalResultCount = 0) => {
    if (totalResultCount > 1) {
        return 'cars ';
    }
    return 'car ';
};

const hasLookForContent = (modelCode) => {
    const summaryPhraseCodes = ['MDX', 'A3', 'A4', 'M3', 'CAM', 'CORV', 'CRUZE', 'EQUINOX', 'TAHOE', 'EDGE', 'ESCAPE', 'EXPLOR', 'F150PICKUP', 'F250', 'FOCUS', 'FUSION', 'MUST', 'ACCORD', 'CIVIC', 'CRV', 'PILOT', 'CHER', 'JEEPGRAND', 'WRANGLER', 'OPTIMA', 'SORENTO', 'SOUL', '350Z', 'RM2500', 'FOREST', 'IMPWRX', 'CAMRY', 'COROL', 'FJCRUIS', 'HIGHLANDER', 'LC', 'PRIUS', 'RAV4', 'TACOMA', 'TUNDRA', 'JET'];
    return modelCode && summaryPhraseCodes.includes(modelCode);
};

// totalResultCount, condition, makeDescription, styleModel, fuelTypeSummaryContent, carText, privateSeller, priceRange, location, sampleListings, priceStats
const getMetaDescriptionAddOn = (query, pageName, listings, totalResultCount = 0, listingType, make, style, model, minPrice, maxPrice, brand) => {
    let template = '';
    if (getCustomPageName(brand, query, pageName) === 'srp') {
        const target = 'lsc';
        const listingCountText = (totalResultCount > 5) ? ' Search from {0} ' : '';
        template = (totalResultCount > 0) ? listingCountText + '{1}{2}{3}{4}{5}{6}for sale{7}{8}.' : '';
        let styleModel = false;
        if (style) {
            styleModel = style.trim();
            if (styleModel && totalResultCount > 0) {
                styleModel = styleModel.concat('s');
            }
        } else if (model) {
            styleModel = model.trim();
        }

        const fuelTypeSummaryContent = !hasLookForContent(model) ? getFuelTypeSummaryContent(query) : '';
        const carText = !hasLookForContent(model) && !style ? getCarText(totalResultCount) : '';
        const sampleListings = sampleListingTitles(listings, listingType, brand);
        const priceStats = assignPriceStats(minPrice, maxPrice);

        template = template.replace('{0}', totalResultCount);
        template = template.replace('{1}', separate(listingType));
        template = template.replace('{2}', separate(make));
        template = template.replace('{3}', separate(styleModel));
        template = assignTrim(template, query, target, null, { brand, pattern: '{4}' });
        template = template.replace('{5}', fuelTypeSummaryContent);
        template = template.replace('{6}', carText);
        template = template.replace('{7}', sampleListings);
        template = template.replace('{8}', priceStats);
    }

    return template;
};

const capitalFirst = (s) => {
    if (s && s.length > 0) {
        return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    }
    return s;
};

const listingInfo = (listing = {}, attrName) => _get(listing, attrName, '');

const decodeListingTypes = (listingType) => {
    if (listingType === '3P_CERT') {
        return 'Used';
    }
    return capitalFirst(listingType);
};

const getFormattedSelfServiceSeoData = (srpSelfServiceSeo, query, address, isNational) => {
    const {
        h1,
        meta_description: selfServiceMetaDescription,
        title,
        withLocation,
        id: queryId,
    } = srpSelfServiceSeo || {};

    let city;
    let state;

    if (address && Object.keys(address).length) {
        city = address.city;
        state = address.state;
    } else {
        city = query.city;
        state = query.state;
    }

    const selfServiceLocationHeading = !isNational && h1 && !withLocation ? `${h1} in ${city}, ${state}` : h1;
    const selfServiceWindowTitle = !isNational && title && !withLocation ? title.replace('- Autotrader', `in ${city}, ${state} - Autotrader`) : title;

    return {
        selfServiceLocationHeading,
        selfServiceMetaDescription,
        selfServiceWindowTitle,
        queryId,
    };
};

export default function withMetaDescription({
    pageName = '',
} = {}) {
    return async (ctx) => {
        const target = 'lsc';
        const listingTypeKey = getKeys('listingType')[target];
        const makeCodeKey = getKeys('makeCode')[target];
        const modelCodeKey = getKeys('modelCode')[target];
        const seriesCodeKey = getKeys('seriesCode')[target];

        const { data, query, srpSelfServiceSeo, match } = ctx;
        const { totalResultCount } = data;
        const { url, isNational } = match; // only add location data to head meta fields if there is no location data in url
        const brand = _get(data, 'brand', 'atc');
        const legacyData = _get(data, 'pageData', {});
        const stats = data?.stats;
        const listings = _get(data, 'listings', []);
        const makeCode = stringElement(query, makeCodeKey);
        const { payload: makeCodes } = await getReference('makeCode');
        const { payload: driveCodes } = await getReference('driveGroup');
        const { payload: fuelCodes } = await getReference('fuelTypeGroup');
        const { payload: vehicleStyleCodes } = await getReference('vehicleStyleCode');
        const { payload: modelCodes } = makeCode ? await getReference('modelCode', { makeCode }) : {};
        const { payload: seriesCodes } = makeCode ? await getReference('seriesCode', { makeCode }) : {};
        const { payload: engineCodesRef } = await getReference('engineCode');
        const engine = assignEngineData(query, target, engineCodesRef);
        const { payload: fuelTypeRef } = await getReference('fuelTypeGroup');
        const fuel = assignFuelData(query, fuelTypeRef);

        const stylePlus = _get(getStylePlus(query, target, driveCodes, fuelCodes, vehicleStyleCodes), 'name', '');
        const stylePlusCode = _get(getStylePlus(query, target, driveCodes, fuelCodes, vehicleStyleCodes), 'code', '');
        const modelCode = stringElement(query, modelCodeKey);
        const seriesCode = stringElement(query, seriesCodeKey);
        const listingTypeCode = decodeListingTypes(stringElement(query, listingTypeKey));
        const style = _get(getStyleOnly(query, target, vehicleStyleCodes), 'name', '');
        const make = _get(getOption(makeCode, makeCodes), 'name', '');
        const model = _get(getOption(modelCode, modelCodes), 'name', '');
        const series = _get(getOption(seriesCode, seriesCodes), 'name', '');
        const electricRange = getElectricMileRange(stringElement(query, 'electricMileRange'));
        // vdp
        const listing = _get(data, 'base', null);
        const trim = listing ? listingInfo(listing, 'trim') : null;
        const owner = _get(listing, 'owners', []);
        const location = _get(owner[0], 'location', {});
        const title = listingInfo(listings[0], 'title');
        const bodyStyles = _get(listings[0], 'bodyStyles', []);
        const bodyStyleName = listingInfo(bodyStyles[0], 'name');
        const listingId = listingInfo(listings[0], 'id');
        const { address } = location;

        const {
            selfServiceLocationHeading,
            selfServiceMetaDescription,
            selfServiceWindowTitle,
            queryId,
        } = getFormattedSelfServiceSeoData(srpSelfServiceSeo, query, address, isNational);

        let primarySelection = null;
        let composedLocationHeading = '';
        let metaDescriptionAddOn = '';

        const minPrice = formatCurrency(stats?.derivedprice?.min);
        const maxPrice = formatCurrency(stats?.derivedprice?.max);

        if (listing) {
            primarySelection = primarySelectionName(query, target, getListingType(listing, brand), listingInfo(listing, 'make'), listingInfo(listing, 'series'), listingInfo(listing, 'model'), listingInfo(listing, 'trim'), null, null, null, null, listingInfo(listing, 'year'), brand);
        } else {
            primarySelection = primarySelectionName(query, target, listingTypeCode, make, series, model, undefined, electricRange, stylePlus, stylePlusCode, style, null, brand);
            composedLocationHeading = selfServiceLocationHeading ?? processTemplate(target, getKey(query, 'pageheading', isNational, false), pageTitles, brand, pageName, query, primarySelection, engine, fuel);
            metaDescriptionAddOn = getMetaDescriptionAddOn(query, pageName, listings, totalResultCount, listingTypeCode, make, style, model, minPrice, maxPrice, brand);
        }

        const isGeolocated = !!(query?.state || query?.city || query?.zip);
        const { metadata } = brand === 'ford' && !isGeolocated && fbaCustomMetadata.find((element) => element.regex.test(url)) || {};
        const composedMetaDescription = selfServiceMetaDescription
            ?? assignTrim(metadata?.desc, query, target, trim, { brand })
            ?? processTemplate(target, getKey(query, 'metadesc', isNational), metaDescriptions, brand, pageName, query, primarySelection, engine, fuel, address) + metaDescriptionAddOn;
        const composedWindowTitle = selfServiceWindowTitle
            ?? assignTrim(metadata?.title, query, target, trim, { brand })
            ?? processTemplate(target, getKey(query, 'windowtitle', isNational), windowTitles, brand, pageName, query, primarySelection, engine, fuel, address, bodyStyleName, listingId, title);
        const composedKeywords = processTemplate(target, 'metakeywords', metaDescriptions, brand, pageName);
        const pageData = {
            selfServiceWindowTitle,
            selfServiceMetaDescription,
            selfServiceLocationHeading,
            metaDescription: composedMetaDescription,
            openGraphDescription: composedMetaDescription,
            windowTitle: composedWindowTitle,
            locationHeading: composedLocationHeading,
            queryId,
        };
        if (composedKeywords !== '') {
            pageData.metaKeywords = composedKeywords;
        }

        // TODO we have multiple middlewares trying to set pageData and need to separate them out so we don't have to do
        // this stupid merge.  Probably should just get rid of pageData entirely
        ctx.data.pageData = deepmerge(legacyData, pageData);
    };
}
