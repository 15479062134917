import getConfig from 'next/config';

import { withCtxMiddleware } from '@bonnet/next/ctx-middleware';
import { withCtxAbortOnStatusCode } from '@bonnet/next/ctx-middleware/with-ctx-abort-on-status-code';
import { withCtxQueryValidation } from '@bonnet/next/ctx-middleware/with-ctx-query-validation';

import atcId from '@atc/bonnet-ctx-atc-id';
import withBrand from '@atc/bonnet-ctx-brand';
import withLegacyParams from '@atc/bonnet-ctx-legacy-params';
import withPageName from '@atc/bonnet-ctx-page-name';
import withParseSeo from '@atc/bonnet-ctx-parse-seo';
import withSeoRedirect from '@atc/bonnet-ctx-seo-redirect';
import withUserLocation from '@atc/bonnet-ctx-user-location';

import { addOptimizelyDynamicConfig } from 'reaxl-optimizely';

import { brandDuck, userDuck } from '@/ducks';

import addPageMetaData from './addPageMetaData';
import addReqProps from './addReqProps';
import handleLegacyServerRedirect from './handleLegacyServerRedirect';
import withAdParameters from './withAdParameters';
import withClientPEData from './withClientPEData';
import withCurrentPageName from './withCurrentPageName';
import withCurrentUrl from './withCurrentUrl';
import withDataIsland from './withDataIsland';
import withLocationType from './withLocationType';
import withMetaDescriptionTitle from './withMetaDescriptionTitle';
import withSeoDecodedPath from './withSeoDecodedPath';
import withUseFeatures from './withUseFeatures';
// TODO: use and update @atc/bonnet-ctx-ad-params if necessary
// import addAdParameters from '@atc/bonnet-ctx-ad-params';

const noop = () => { };

const { publicRuntimeConfig = {} } = getConfig();
const { environment } = publicRuntimeConfig;

export default function ctxPresetFindCar({
    dispatcher = noop,
    preFetchCtxMiddleware = [],
    fetcher = noop,
    pageName,
}) {
    return (ctx) => withCtxMiddleware([
        withUseFeatures({ environment }),
        atcId(),
        addOptimizelyDynamicConfig({ environment }),
        // determine experiment before choosing legacy params target
        withUseFeatures(),
        withSeoDecodedPath(),

        // add page name to context
        withPageName(pageName, {}, {
            required: (config) => config,
            message: 'presetAtc requires pageName be passed in as presetAtc({ pageName: <String> })',
        }),

        // cleanse query of empty or undefined parameters
        // withQueryEmpty(),

        // define and assign brand value
        withBrand({ brandDuck }),

        // parse seo url parameters
        withParseSeo(),

        // optionally translate monolith param keys to LSC or vice versa
        withLegacyParams(),

        // validate atc query parameters
        withCtxQueryValidation({
            validations: {
                endYear: (value) => /^\d{4}$/.test(value),
                firstRecord: (value) => value >= -1 && value < 999,
                mileage: (value) => /^\d+$/.test(value),
                maxPrice: (value) => /^\d+$/.test(value),
                minPrice: (value) => /^\d+$/.test(value),
                listingId: (value) => /^\d+(,\d+)*$/.test(value),
                numRecords: (value) => value >= -1 && value <= 120,
                searchRadius: (value) => value >= 0 && value <= 500,
                startYear: (value) => /^\d{4}$/.test(value),
            },
        }),
        withUserLocation({
            // disable client-side geolocation
            useGeolocation: false,
            userDuck,
        }),
        withSeoRedirect({
            allowedPatterns: {
                'sub-0': 'soul/+',
                'sub-1': 'soul/ev-+',
            },
        }),
        withCurrentPageName(),
        withLocationType(), // determine if page is a national page.
        withCtxAbortOnStatusCode(),
        withCurrentUrl(),
        withClientPEData(),
        ...preFetchCtxMiddleware,
        // does main fetch call to legacy base endpoint
        fetcher(),
        // legacy base servuces may contain a server redirect command
        handleLegacyServerRedirect(),
        addPageMetaData({ pageName }),
        withDataIsland(),
        withMetaDescriptionTitle({ pageName }),
        withAdParameters(),
        addReqProps(),
        dispatcher(),
    ], ctx);
}
