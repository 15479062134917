// eslint-disable-next-line import/prefer-default-export
export const fbaCustomMetadata = [
    {
        regex: /^\/cars-for-sale\/all\/suv-crossover$/,
        metadata: {
            title: 'Certified Used SUVs for Sale | Ford Blue Advantage',
            desc: 'Shop certified used SUVs and crossovers backed by the Ford Blue Advantage program. Find certified pre-owned SUVs for sale near you.',
        },
    },
    {
        regex: /^\/cars-for-sale\/all\/truck\/ford$/,
        metadata: {
            title: 'Certified Used Ford Trucks | Ford Blue Advantage',
            desc: 'Find certified used Ford trucks backed by the Ford Blue Advantage program. Shop certified pre-owned Ford trucks, like the Ranger, for sale near you.',
        },
    },
    {
        regex: /^\/cars-for-sale\/all\/truck$/,
        metadata: {
            title: 'Certified Used Trucks for Sale | Ford Blue Advantage',
            desc: 'Find certified used trucks backed by the Ford Blue Advantage program. Shop certified pre-owned trucks for sale near you.',
        },
    },
    {
        regex: /^\/cars-for-sale\/all\/van-minivan$/,
        metadata: {
            title: 'Certified Used Vans for Sale | Ford Blue Advantage',
            desc: 'Shop certified used vans and minivans backed by the Ford Blue Advantage program. Find certified pre-owned vans and minivans for sale near you.',
        },
    },
    {
        regex: /^\/cars-for-sale\/all\/cars-under-15000$/,
        metadata: {
            title: 'Certified Used Cars Under $15,000 | Ford Blue Advantage',
            desc: 'Find certified used cars for sale  $15,000. Shop cars within a $15,000 budget that are backed by the Ford Blue Advantage program.',
        },
    },
    {
        regex: /^\/cars-for-sale\/all\/cars-under-20000$/,
        metadata: {
            title: 'Certified Used Cars Under $20,000 | Ford Blue Advantage',
            desc: 'Shop certified used vehicles for sale under $20,000. Shop cars, trucks, and SUVs under $20,000 that are backed by the Ford Blue Advantage program.',
        },
    },
    {
        regex: /^\/cars-for-sale\/all\/hybrid$/,
        metadata: {
            title: 'Certified Used Hybrid Cars | Ford Blue Advantage',
            desc: 'Shop certified used hybrid vehicles backed by the Ford Blue Advantage program. Find certified pre-owned hybrid cars, trucks, and SUVs for sale near you.',
        },
    },
    {
        regex: /^\/cars-for-sale\/all\/electric$/,
        metadata: {
            title: 'Certified Used Electric Cars | Ford Blue Advantage',
            desc: 'Find certified used electric vehicles backed by the Ford Blue Advantage program. Shop certified pre-owned electric cars for sale near you.',
        },
    },
    {
        regex: /^\/cars-for-sale\/all\/ford\/explorer\/?.*$/,
        metadata: {
            title: 'Certified Used Ford Explorers @@trim@@| Ford Blue Advantage',
            desc: 'Shop certified used Ford Explorers @@trim@@backed by the Ford Blue Advantage program. Find certified pre-owned Explorers, including Sport and Platinum trims, for sale.',
        },
    },
    {
        regex: /^\/cars-for-sale\/all\/ford\/f150\/?.*$/,
        metadata: {
            title: 'Certified Used Ford F150 @@trim@@Trucks | Ford Blue Advantage',
            desc: 'Find certified used Ford F150 @@trim@@trucks backed by the Ford Blue Advantage program. Shop certified pre-owned F150s, including Lariat and Raptor trims, for sale.',
        },
    },
    {
        regex: /^\/cars-for-sale\/all\/ford\/f250\/?.*$/,
        metadata: {
            title: 'Certified Used Ford F250 @@trim@@Trucks | Ford Blue Advantage',
            desc: 'Shop certified used Ford F250 @@trim@@trucks backed by the Ford Blue Advantage program. Find certified pre-owned Ford F250s, including XLT and Lariat trims, for sale.',
        },
    },
    {
        regex: /^\/cars-for-sale\/all$/,
        metadata: {
            title: 'Certified Pre-Owned Vehicles | Ford Blue Advantage',
            desc: 'Find certified pre-owned cars, trucks, and SUVs backed by Ford. All Ford CPO vehicles pass a multi-point inspection before becoming Gold or Blue certified.',
        },
    },
];
